<template>
  <div class="input-setting-dialog">
    <div class="setting-block">
      <div class="dialog-body setting-block__container">
        <div class="setting-block__title">
          {{ $t('model.upload.outputArgsSetting') }}
        </div>
        <div class="setting-block__subtitle">
          <span class="label">{{ $t('model.outputType') }}</span>
          {{ outputType }}
        </div>
        <div class="setting-block__warning">
          <svg-icon icon-class="data-explanation" />
          {{ $t('model.upload.argsReminder', {mainScriptName}) }}
        </div>
        <div class="setting-block__warning">
          <svg-icon icon-class="data-explanation" />
          {{ $t('model.upload.columnReminder') }}
        </div>
        <draggable
          v-model="columnList"
        >
          <model-column-setting-card
            v-for="column in columnList"
            :column-info="column"
            :data-type-option-list="availableStatsTypeOptionList"
            :column-list="columnList"
            :model-type="currentUploadModelInfo.modelType"
            :key="column.id"
            :is-processing="isProcessing"
            @updateDataColumn="updateDataColumn($event, column.id)"
            @remove="removeColumnCard"
          />
        </draggable>
        <div class="input-block">
          <div class="input-block__label">
            {{ $t('model.paramsSetting.addColumn') }}
          </div>
          <div class="input-block__value">
            <input-verify
              v-model.number.trim="itemsPerAdd"
              name="addItem"
              type="text"
            />
          </div>
          <button
            :disabled="isProcessing"
            class="btn btn-m btn-outline btn-has-icon"
            @click="addNewColumnCard()"
          >
            <svg-icon
              icon-class="plus"
              class="icon"
            />{{ $t('button.add') }}
          </button>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="dialog-button-block">
          <button
            :disabled="isProcessing"
            class="btn btn-outline"
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            :disabled="isProcessing"
            class="btn btn-default"
            @click="buildData"
          >
            <span v-if="isProcessing">
              <svg-icon
                v-if="isProcessing"
                icon-class="spinner"
              />
              {{ $t('button.processing') }}
            </span>
            <span v-else>{{ $t('button.buildData') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import UploadProcessBlock from './fileUpload/UploadProcessBlock'
import DefaultSelect from '@/components/select/DefaultSelect'
import ModelColumnSettingCard from './components/ModelColumnSettingCard'
import InputVerify from '@/components/InputVerify'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'
import { createModel } from '@/API/Model'
import { statsTypeOptionList } from '@/utils/general'
import { Message } from 'element-ui'

export default {
  name: 'OutputSetting',
  inject: ['$validator'],
  components: {
    UploadProcessBlock,
    DefaultSelect,
    ModelColumnSettingCard,
    InputVerify,
    draggable
  },
  data () {
    return {
      columnList: [],
      isProcessing: false,
      statsTypeOptionList,
      itemsPerAdd: 1,
      mainScriptName: 'main.py'
    }
  },
  computed: {
    ...mapState('modelManagement', ['currentUploadModelInfo']),
    dataTypeOptionList () {
      const acceptedDataTypeList = ['FLOAT', 'STRING', 'INT', 'DATETIME', 'BOOLEAN']
      return acceptedDataTypeList.map(type => ({
        name: type,
        value: type
      }))
    },
    modelColumnNames () {
      return this.columnList.map(item => item.modelColumnName || item.modelDataFrameName)
    },
    availableStatsTypeOptionList () {
      const availableStatsTypeSet = new Set(['CATEGORY', 'NUMERIC'])
      return statsTypeOptionList.filter(statsType => availableStatsTypeSet.has(statsType.value))
    },
    outputType () {
      return {
        UPLOADED_SINGLE_RECORD: this.$t('etl.column'),
        UPLOADED_MULTI_DATASET: this.$t('editing.dataFrame')
      }[this.currentUploadModelInfo.modelType]
    }
  },
  mounted () {
    // 預先新增一個欄位選擇器
    this.addNewColumnCard()
  },
  methods: {
    ...mapMutations('modelManagement', ['updateCurrentUploadModelInfo', 'updateShowCreateModelDialog']),
    addNewColumnCard () {
      if (!this.itemsPerAdd) return
      const emptyArray = []
      const length = this.columnList.length
      for (let i = 0; i < this.itemsPerAdd; i++) {
        if (this.currentUploadModelInfo.modelType === 'UPLOADED_MULTI_DATASET') {
          emptyArray.push({
            modelDataFrameName: null,
            id: uuidv4()
          })
        } else {
          emptyArray.push({
            statsType: null,
            modelColumnName: null,
            id: uuidv4()
          })
        }
      }
      this.columnList.splice(length, 0, ...emptyArray)
    },
    updateDataColumn (statesType, selectedColumnCardId) {
      const columnCard = this.columnList.find(columnCard => columnCard.id === selectedColumnCardId)
      columnCard.statsType = statesType
    },
    removeColumnCard (cardId) {
      this.columnList = this.columnList.filter(columnCard => columnCard.id !== cardId)
    },
    cancel () {
      this.updateShowCreateModelDialog(false)
    },
    buildData () {
      this.$validator.validateAll().then(isValidate => {
        if (!isValidate) return

        // 欄位名稱不能重複
        if (this.hasDuplicatedElements(this.modelColumnNames)) {
          Message({
            message: this.$t('model.paramNameDuplicated'),
            type: 'warning',
            duration: 3 * 1000,
            showClose: true
          })
          return
        }
        const outputList = this.columnList.map((column) => {
          const { id, ...rest } = column
          return rest
        })
        this.isProcessing = true
        createModel({
          ...this.currentUploadModelInfo,
          ioArgs: {
            ...this.currentUploadModelInfo.ioArgs,
            output: outputList
          }
        })
          .then(() => {
          // 為了觸發重新撈取資料
            this.$store.commit('modelManagement/updateModelUploadSuccess', true)
            this.updateShowCreateModelDialog(false)
          })
          .catch(() => { this.isProcessing = false })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.input-setting-dialog {
  .setting-block {
    height: 65vh;
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &__container {
      background: rgba(50, 58, 58, 0.95);
      border-radius: 5px;
      padding: 24px;
      overflow: auto;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__subtitle {
      margin-bottom: 16px;
      .label {
        font-weight: 600;
        color: #CCCCCC;
      }
    }

    &__reminder {
      font-size: 12px;
      font-weight: normal;
    }

    &__warning {
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: normal;
      color: var(--color-warning);
    }

    .input-block {
      display: flex;
      align-items: center;
      &__label {
        font-size: 14px;
        font-weight: 600;
        color: #ccc;
      }
      &__value {
        margin: 0 16px;
        background-color: #252C2C;
        border-radius: 5px;
      }

      ::v-deep .input-verify .input-verify-text {
        width: 70px;
        height: 30px;
        margin-bottom: 0;
        padding: 5px 10px;
        border-bottom: none;
      }
    }

    ::v-deep .el-input__inner {
      padding-left: 0;
      border-bottom: 1px solid #FFFFFF;
    }

    ::v-deep .input-field {
      &__label {
        font-size: 14px;
        color: #CCCCCC;
      }

      .el-input__inner {
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

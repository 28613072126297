<template>
  <div class="input-setting-dialog">
    <div class="setting-block">
      <div class="dialog-body setting-block__container">
        <div class="setting-block__title">
          {{ $t('model.upload.inputArgsSetting') }}
        </div>
        <div class="setting-block__radio-group-container">
          {{ $t('model.upload.inputType') }} :
          <div
            v-for="(option, index) in inputOptionList"
            :key="`${option.value}-${index}`"
            class="input-radio-group"
          >
            <input
              :id="`${inputId}-${option.value}`"
              :checked="modelType === option.value"
              :value="option.value"
              class="input-radio"
              type="radio"
              @change="switchModelType(option.value)"
            >
            <label
              :for="`${inputId}-${option.value}`"
              class="input-radio-label"
            >
              {{ option.name }}
            </label>
          </div>
        </div>
        <div class="setting-block__warning">
          <svg-icon icon-class="data-explanation" />
          {{ $t('model.upload.argsReminder', {mainScriptName}) }}
        </div>
        <div class="setting-block__warning">
          <svg-icon icon-class="data-explanation" />
          {{ $t('model.upload.columnReminder') }}
        </div>
        <draggable
          v-model="columnList"
        >
          <model-column-setting-card
            v-for="column in columnList"
            :column-info="column"
            :data-type-option-list="statsTypeOptionList"
            :column-list="columnList"
            :model-type="modelType"
            :key="column.id"
            @updateDataColumn="updateDataColumn($event, column.id)"
            @remove="removeColumnCard"
          />
        </draggable>
        <div class="input-block">
          <div class="input-block__label">
            {{ $t('model.paramsSetting.addColumn') }}
          </div>
          <div class="input-block__value">
            <input-verify
              v-model.number.trim="itemsPerAdd"
              name="addItem"
              type="text"
            />
          </div>
          <button
            class="btn btn-m btn-outline btn-has-icon"
            @click="addNewColumnCard()"
          >
            <svg-icon
              icon-class="plus"
              class="icon"
            />{{ $t('button.add') }}
          </button>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="dialog-button-block">
          <button
            class="btn btn-outline"
            @click="cancel"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            class="btn btn-default"
            @click="next"
          >
            {{ $t('button.nextStep') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import UploadProcessBlock from './fileUpload/UploadProcessBlock'
import DefaultSelect from '@/components/select/DefaultSelect'
import ModelColumnSettingCard from './components/ModelColumnSettingCard'
import draggable from 'vuedraggable'
import InputVerify from '@/components/InputVerify'
import { v4 as uuidv4 } from 'uuid'
import { statsTypeOptionList } from '@/utils/general'
import { Message } from 'element-ui'

export default {
  name: 'InputSetting',
  inject: ['$validator'],
  components: {
    UploadProcessBlock,
    DefaultSelect,
    ModelColumnSettingCard,
    InputVerify,
    draggable
  },
  data () {
    return {
      statsTypeOptionList,
      inputId: uuidv4(),
      columnList: [],
      itemsPerAdd: 1,
      mainScriptName: 'main.py',
      inputOptionList: [
        { name: this.$t('etl.column'), value: 'UPLOADED_SINGLE_RECORD' },
        { name: this.$t('editing.dataFrame'), value: 'UPLOADED_MULTI_DATASET' }
      ],
      modelType: 'UPLOADED_SINGLE_RECORD'
    }
  },
  computed: {
    ...mapState('modelManagement', ['currentUploadModelInfo']),
    modelColumnNames () {
      return this.columnList.map(item => item.modelColumnName || item.modelDataFrameName)
    }
  },
  mounted () {
    this.initColumnList()
  },
  methods: {
    ...mapMutations('modelManagement', ['updateCurrentUploadModelInfo', 'updateShowCreateModelDialog']),
    initColumnList () {
      // 清空原資料
      this.columnList = []
      this.addNewColumnCard()
    },
    addNewColumnCard () {
      if (!this.itemsPerAdd) return
      const emptyArray = []
      const length = this.columnList.length
      for (let i = 0; i < this.itemsPerAdd; i++) {
        if (this.modelType === 'UPLOADED_MULTI_DATASET') {
          emptyArray.push({
            modelDataFrameName: null,
            id: uuidv4()
          })
        } else {
          emptyArray.push({
            statsType: null,
            modelColumnName: null,
            id: uuidv4()
          })
        }
      }
      this.columnList.splice(length, 0, ...emptyArray)
    },
    updateDataColumn (statesType, selectedColumnCardId) {
      const columnCard = this.columnList.find(columnCard => columnCard.id === selectedColumnCardId)
      columnCard.statsType = statesType
    },
    removeColumnCard (cardId) {
      this.columnList = this.columnList.filter(columnCard => columnCard.id !== cardId)
    },
    cancel () {
      this.updateShowCreateModelDialog(false)
    },
    switchModelType (type) {
      this.modelType = type
      this.columnList = {
        UPLOADED_SINGLE_RECORD: this.columnList.map(({ id, modelDataFrameName }) => ({ id, modelColumnName: modelDataFrameName, statsType: null })),
        UPLOADED_MULTI_DATASET: this.columnList.map(({ id, modelColumnName }) => ({ id, modelDataFrameName: modelColumnName }))
      }[type]
    },
    next () {
      this.$validator.validateAll().then(isValidate => {
        if (!isValidate) return

        // 欄位名稱不能重複
        if (this.hasDuplicatedElements(this.modelColumnNames)) {
          Message({
            message: this.$t('model.paramNameDuplicated'),
            type: 'warning',
            duration: 3 * 1000,
            showClose: true
          })
          return
        }
        const inputList = this.columnList.map((column) => {
          const { id, ...rest } = column
          return rest
        })
        this.updateCurrentUploadModelInfo({
          ...this.currentUploadModelInfo,
          ioArgs: {
            input: inputList
          },
          modelType: this.modelType
        })
        this.$emit('next')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.input-setting-dialog {
  .setting-block {
    height: 65vh;
    display: flex;
    flex-direction: column;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &__container {
      background: rgba(50, 58, 58, 0.95);
      border-radius: 5px;
      padding: 24px;
      overflow: auto;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__radio-group-container {
      display: inline-block;
      font-size: 14px;
      color: #CCCCCC;
      margin-bottom: 8px;
      .input-radio {
        &:checked + label:before,
        &:not(:checked) + label:before {
          width: 14px;
          height: 14px;
        }

        &:checked + label:after,
        &:not(:checked) + label:after {
          left: 4px;
        }
      }
      .input-radio-group{
        &:not(:last-of-type) {
          margin: 0 15px 0 4px;
        }
      }
      .input-radio-label {
        padding-left: 24px;
      }
    }

    &__warning {
      margin-bottom: 8px;
      font-size: 13px;
      font-weight: normal;
      color: var(--color-warning);
    }

    .input-block {
      display: flex;
      align-items: center;
      &__label {
        font-size: 14px;
        font-weight: 600;
        color: #ccc;
      }
      &__value {
        margin: 0 16px;
        background-color: #252C2C;
        border-radius: 5px;
      }

      ::v-deep .input-verify .input-verify-text {
        width: 70px;
        height: 30px;
        margin-bottom: 0;
        padding: 5px 10px;
        border-bottom: none;
      }
    }

    ::v-deep .el-input__inner {
      padding-left: 0;
      border-bottom: 1px solid #FFFFFF;
    }

    ::v-deep .input-field {
      &__label {
        font-size: 14px;
        color: #CCCCCC;
      }

      .el-input__inner {
        &::placeholder {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
